<template>
  <div class="flex flex-col p-4 space-y-2">
    <div class="flex space-x-2">
      <div class="w-1/2">
        <PDatePicker
          class="w-full"
          :label="$tk('Common.Order.Sent')"
          :min="minDate"
          v-model="order.dateSend"
          :required="true"
          :clearable="true"
        />
      </div>
      <div class="w-1/2">
        <PInput
          :label="$tk('Common.Order.Reference')"
          v-model="order.sendersRef"
        />
      </div>
    </div>
    <div>
      <PLocationPicker
        :label="$tk('Common.General.Location')"
        :value="locationId"
        :focus="true"
        :pooling="true"
        :corporate="true"
        :required="true"
        :disabled="isSubmitting"
        @select="onLocationToSelect"
        :excludeSr="true"
      />
    </div>
    <div>
      <PTextarea
        :label="$tk('Common.General.Comment')"
        :autosize="true"
        v-model="commentContent"
      />
    </div>
    <div>
      <PLabel label="Produkt" :required="true" />
      <PLoader v-if="isLoadingAvailableProducts" />
      <div
        class="text-sm italic"
        v-else-if="locationId && products.length === 0"
      >
        {{ $t("PalletReceipts.NoProductsAvailableForThisLocation") }}
      </div>
      <div class="text-sm italic" v-else-if="!locationId">
        {{ $t("PalletReceipts.PleaseSelectLocationFirst") }}
      </div>
      <div v-else class="flex flex-col space-y-2">
        <div
          v-for="(product, index) in products"
          :key="index"
          class="flex items-center justify-between w-full px-3 py-2 border rounded-md"
          :class="{
            'bg-gradient-to-b from-gray-50 to-gray-200  border-gray-300':
              product.quantity === 0,
            'bg-green-100 border-green-500': product.quantity,
          }"
        >
          <div class="flex items-center space-x-2">
            <img
              :src="
                `${assetsBaseUrl}/media.ashx?${product.image}&template=product-image-69`
              "
            />
            <span>{{ product.name }}</span>
          </div>
          <PNumber class="w-28" v-model="product.quantity"></PNumber>
        </div>
      </div>
    </div>
    <div>
      <PLabel label="Signatur" :required="customer.palletReceiptSignature" />
      <div class="bg-white border border-gray-400 rounded">
        <VueSignaturePad width="100%" height="100px" ref="signaturePad" />
      </div>
    </div>
    <div class="flex pt-2 space-x-4">
      <PButton class="w-full" color="danger" @click="deleteOrder" v-if="id">
        {{ $tk("Common.Actions.Delete") }}
      </PButton>
      <PButton class="w-full" color="secondary" @click="reset">
        {{ $tk("Common.Actions.Reset") }}
      </PButton>
      <PButton
        class="w-full"
        color="primary"
        :disabled="!isValid || isSubmitting"
        @click="submit"
      >
        {{ $tk("Common.Actions.Confirm") }}
      </PButton>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
import { mapGetters } from "vuex";
import http from "@/utilities/http";
import PLabel from "../../../components/forms/partials/PLabel.vue";
import { map, sumBy, forEach } from "lodash";

export default {
  name: "PalletReceipts",
  props: {
    id: {
      type: String,
      required: false,
    },
    inv: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      locationTo: null,
      commentId: "",
      commentContent: "",
      order: {
        dateSend: "",
        locationIdFrom: "",
        locationIdTo: "",
        sendersRef: "",
        transactions: [
          {
            productId: "",
            qualityId: "",
            treatmentId: "",
            quantityOrdered: 0,
          },
        ],
        transactionType: "",
      },
      activeTab: "form",
      isSubmitting: false,
      isLoadingAvailableProducts: false,
      products: [],
    };
  },
  computed: {
    ...mapGetters(["customer", "location"]),
    assetsBaseUrl() {
      return this.$appConfig.assetsBaseUrl;
    },
    quantitiesTotal() {
      return sumBy(this.products, (product) => product.quantity);
    },
    isValid() {
      return (
        this.quantitiesTotal > 0 &&
        this.order.dateSend !== "" &&
        this.locationId !== ""
      );
    },
    minDate() {
      return format(new Date(), "yyyy-MM-dd");
    },
    locationId: {
      get () {
        return this.inv ? this.order.locationIdFrom : this.order.locationIdTo;
      },
      set (value) {
        if (this.inv) {
          this.order.locationIdFrom = value;
        } else {
          this.order.locationIdTo = value;
        }
      },
    }
  },
  methods: {
    async submit() {
      let error = false;

      this.isSubmitting = true;

      this.order.transactions = [];
      this.products.forEach((product) => {
        if (product.quantity > 0) {
          let transaction = {
            productId: product.id,
            qualityId: product.qualityId,
            treatmentId: product.treatmentId,
            quantityOrdered: product.quantity,
          };

          this.order.transactions.push(transaction);
        }
      });

      const orderData = { ...this.order, commit: true };

      if (this.id) {
        orderData.id = this.id;
      }

      let order = null;

      try {
        order = await http.post("order", orderData);
      } catch {
        this.$notifyError(
          this.$t("Common.General.AnUnexpectedErrorOccuredPleaseTryAgain")
        );
        error = true;
      }

      if (!error) {
        try {
          await http.post("OrderStatus", {
            id: order.id,
            transactionStatus: 4,
          });
        } catch {
          error = true;
          this.$notifyError(this.$t("PalletReceipts.CouldNotSetOrderStatus"));
        }
      }

      if (!error) {
        try {
          await this.crudComment(order.id);
        } catch {
          error = true;
          this.$notifyError(this.$t("PalletReceipts.CouldNotSaveComment"));
        }
      }

      if (!error) {
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
        if (!isEmpty) {
          try {
            await http.post("Store", {
              scope: "none",
              scopeId: this.customer.id,
              name: `digipall_${order.id}`,
              type: "image/png",
              value: data.substring("data:image/png;base64,".length),
            });
          } catch {
            error = true;
            this.$notifyError(this.$t("PalletReceipts.CouldNotSaveSignature"));
          }
        }
      }

      this.reset();

      this.isSubmitting = false;

      this.$notifySuccess(this.$t("PalletReceipts.PalletReceiptSaved"));
    },
    async crudComment(orderId) {
      // delete comment if existing and text is blank
      if (this.commentId && this.commentContent === "") {
        await http.delete(`OrderComment?id=${this.commentId}`);
        this.commentId = "";

        // create or update if text is not blank
      } else if (this.commentContent !== "") {
        let data = {
          orderId,
          content: this.commentContent,
        };
        if (this.commentId) {
          data.id = this.commentId;
        }

        // post to ordercomment returns comment id
        this.commentId = await http.post("OrderComment", data);
      }
    },
    reset() {
      if (this.id) {
        this.$router.push({ name: "palletReceipts" });
      }
      this.order.dateSend = format(new Date(), "yyyy-MM-dd");
      this.locationId = "";
      this.order.sendersRef = "";
      this.order.transactions[0].quantityOrdered = 0;
      this.commentContent = "";
      this.$refs.signaturePad.clearSignature();
    },
    async onLocationToSelect(location) {
      const transactionType =
        location.customerId === this.customer.id ? "OO" : "GL";

      this.locationId = location.id;
      this.order.transactionType = transactionType;
      this.locationTo = location;

      const params = {
        locationId: this.location.id,
        customerId: this.customer.id,
        transactionType,
        isCommission: true,
      };

      if (this.customer.palletReceiptQualityId) {
        params.qualityId = this.customer.palletReceiptQualityId;
      }

      this.isLoadingAvailableProducts = true;

      const products = await http.get("availableProducts", {
        params,
      });

      this.isLoadingAvailableProducts = false;

      this.products = map(products, (product) => {
        return {
          name: product.productName,
          id: product.productId,
          qualityId: product.qualityId,
          treatmentId: product.treatmentId,
          image: product.image,
          qtyMax: product.qtyMax,
          quantity: 0,
        };
      });
    },
    async deleteOrder() {
      if (confirm(this.$t("PalletReceipts.DeleteConfirm"))) {
        await http.delete("order", { params: { id: this.id } });
        this.reset();
        this.$notifySuccess(this.$t("PalletReceipts.PalletReceiptDeleted"));
      }
    },
  },
  async created() {
    this.order.dateSend = format(new Date(), "yyyy-MM-dd");

    if (this.inv) {
      this.order.locationIdTo = this.location.id;
    } else {
      this.order.locationIdFrom = this.location.id;
    }

    if (this.id) {
      const order = await http.get("order", { params: { id: this.id } });
      const location = await http.get("location", {
        params: { id: this.locationId },
      });

      this.order.dateSend = order.dateSend;
      this.order.sendersRef = order.sendersRef;

      await this.onLocationToSelect(location);

      forEach(order.transactions, (transaction) => {
        forEach(this.products, (product) => {
          if (
            product.id === transaction.productId &&
            product.qualityId === transaction.qualityId &&
            product.treatmentId === transaction.treatmentId
          ) {
            product.quantity = transaction.quantityOrdered;
          }
        });
      });

      const comments = await http.get("OrderComments", {
        params: {
          orderId: this.id,
        },
      });
      if (comments.length > 0 && comments[0] !== null) {
        this.commentId = comments[0].id;
        this.commentContent = comments[0].content;
      }
    }
  },
  components: { PLabel },
};
</script>
